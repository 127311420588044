// 引入组件库
import Vue from 'vue';

// 国际化支持
import Locale from 'vant/lib/locale';
import enUS from 'vant/lib/locale/lang/en-US';

// 按需引入组件
import NavBar from 'vant/lib/nav-bar';
import Popup from 'vant/lib/popup';
import Overlay from 'vant/lib/overlay';
import Swipe from 'vant/lib/swipe';
import SwipeItem from 'vant/lib/swipe-item';
import Picker from 'vant/lib/picker';
import Icon from 'vant/lib/icon';
import Cell from 'vant/lib/cell';
import Tab from 'vant/lib/tab';
import Tabs from 'vant/lib/tabs';
import Button from 'vant/lib/button';
import List from 'vant/lib/list';
import Loading from 'vant/lib/loading'
import Notify from 'vant/lib/notify';
import Toast from 'vant/lib/toast';
import Sticky from 'vant/lib/sticky';
import Collapse from 'vant/lib/collapse';
import CollapseItem from 'vant/lib/collapse-item';
import Rate from 'vant/lib/rate';
import DropdownMenu from 'vant/lib/dropdown-menu';
import DropdownItem from 'vant/lib/dropdown-item';
import Field from 'vant/lib/field';
import Form from 'vant/lib/form';
import Switch from 'vant/lib/switch';
import Checkbox from 'vant/lib/checkbox';
import CheckboxGroup from 'vant/lib/checkbox-group';
import Skeleton from 'vant/lib/skeleton';
import CountDown from 'vant/lib/count-down';
import Dialog from 'vant/lib/dialog';
import Popover from 'vant/lib/popover'
import Stepper from 'vant/lib/stepper';
import Lazyload from 'vant/lib/lazyload';
import ImagePreview from 'vant/lib/image-preview'
// 注册英文语言包
Locale.use('en-US', enUS);

// 注册组件
Vue.use(NavBar);
Vue.use(Popup);
Vue.use(Overlay);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(List);
Vue.use(Loading);
Vue.use(Sticky);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Rate);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Field);
Vue.use(Form);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Skeleton);
Vue.use(CountDown);
Vue.use(Dialog);
Vue.use(Popover)
Vue.use(Stepper)
Vue.use(Lazyload)
Vue.use(ImagePreview)
Vue.prototype.$Notify = Notify;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = Dialog;
