<template>
  <div v-if="!$store.state.appDevice && !isAppJsInject" id="header" class="header" :class="[{ 'sticky': stickyStatus }, `header-${locale}`]">
    <div class="topbar" :class="[{ 'boxshadow': displayBoxshadow }, themeClass]">
      <!-- 左侧部分 -->
      <div class="flex-left">
        <div class="header-button">
          <!-- 返回按钮 -->
          <template v-if="showBack">
            <i class="iconfont icon-arrow-left-outline" aria-label="Back Button" @click="handleBack" />
          </template>
          <template v-else>
            <div class="logo">
              <a :href="siteJumpUrl" :title="$t('common.websiteName')" aria-label="Banner">
                <img v-lazy-load :data-src="logoUrl" :alt="$t('common.websiteName')">
              </a>
            </div>
          </template>
        </div>
      </div>
      <!-- 中间部分 -->
      <div class="flex-center">
        <!-- 页面标题 -->
        <template v-if="title">
          <div class="title" @click="handleTitleClick" v-html="title" />
        </template>
        <!-- 无页面标题统一显示LOGO -->
      </div>
      <!-- 中间部分 -->
      <!-- 右侧部分 -->
      <div class="flex-right">
        <TimezoneMark class="timezone-mark-wrapper" />
        <template v-if="locale === 'am' || locale === 'ae'">
          <i class="iconfont icon-menu-outline" @click="handleNavPopup" />
        </template>
        <template v-else>
          <img v-lazy-load data-src="~assets/images/header/rightImg.png" alt="menu" class="menuImg" aria-label="Right Menu" @click="handleNavPopup">
        </template>
      </div>
      <!-- 右侧部分 -->
    </div>
    <!-- 导航菜单 -->
    <van-popup v-model="showNavigation"
               position="right"
               class="navigation"
               :style="{ height: '100%' }"
    >
      <nuxt-link class="user-status wrapper" :to="isLogin?'/student-portal':loginPath">
        <template v-if="isLogin">
          <div class="circular-head">
            <img v-if="hasUnpaidList" class="mark" v-lazy-load data-src="~assets/images/my-account/unpaid_icon.png" alt="">
            <img v-if="userInfo.parentAvatar" :data-not-lazy="true" v-lazy-load :data-src="`${userInfo.parentAvatar}?im=Resize=(120,120)`" :alt="userInfo.parentNickname">
          </div>
          <div class="user-info">
            <p class="userName text-overflow-ellipsis-2">
              {{ userInfo.parentNickname || $t('common.parent') }}
            </p>
          </div>
          <div class="icon-arrow">
            <i class="iconfont icon-arrow-right-outline" />
          </div>
        </template>
        <div v-else class="userName">
          <div class="button button-normal button-primary-white sign-btn">
            {{ langContext.signIn }}
          </div>
        </div>
      </nuxt-link>
      <div v-if="siteShowNav" class="navigation-wrapper">
        <HeaderNavTree v-for="item in mainNavList" :key="item.name" :item="item" @closeNavigation="handleCloseNavPopup" @click-nav-item-sa="handleClickNav" />
      </div>
    </van-popup>
    <div v-if="tipsList.length > 0 && isShowTipsBox" class="tips-box">
      <div v-for="(item, index) in tipsList" :key="index">
        <div v-if="item.show" class="tips-an">
          <div class="card">
            <div class="l">
              <img v-lazy-load :data-src="item.avatar">
            </div>
            <div class="r">
              <p class="p1">
                <!-- Mary Mack There is a diagnostic assessment, don't forget to take it! -->
                {{ item.firstName+' '+item.lastName }}, <span @click="goAppointment(item)">click here</span>{{ $t('page.guidePopup')[item.tag] }}
              </p>
              <!-- <p class="p2">
                <span @click="goAppointment(item)">Click here</span> View Appointment History
              </p> -->
            </div>
          </div>
          <div class="close" @click="close(item, index)">
            <img v-lazy-load data-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAZZJREFUaEPtmDFOxTAMhu0IBo7AzImYOvAWblE1c6reggWGN3EiZo7AwFOMgl6l6OmlbWy3VSBd0zj/599xkyIU/mDh+qEC7O1gdaA6IMxALSFhAsXTqwPiFAoD/G8Huq677/v+U5JEaQy2A9baRyJ6Q8Rn59yRA2GtbYjoBRGfnHPvnBgsgCDee39ExFsAOCHiIRfiLP4VAG6I6NsY03AgsgGC5QDwAQB3UcayIGLxUYwvAHjILclsgLBgQsAiCMncayXGAuBCaIsPOtgAuRBriBcDLIVYS7wKwBxEGCei327D3fRT7VVUQnHgVJbP76wiXs2BESQBEXMu6lQ5HzQ1BxZAqItXdyAEbNu2McZc1nwYOnnvD8MwsI4dKVdUHZgQP66vDqEGUPQmTokPJRNSf6Wk1PaD2IEp8WO9J0pLBUIEkPOFzXl3kzbKEcSZMwfDckAiRDJX5Tj9Vy405V4poyNDuZf6EUL6SyTEkcZgbeK5zrDleAXYMtsqbXRvwZfr1xLa25HqQHVAmIFaQsIEiqcX78APaZ1cQCqBEeMAAAAASUVORK5CYII=">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Storage } from 'common/utils';
import TimezoneMark from '@/components/global/FeatureComponents/TimezoneMark/touch'
import HeaderNavTree from './HeaderNavTree/touch'
import mixin from './mixin'
import { isNativeCourses } from 'common/clientVersion'

export default {
  components: {
    HeaderNavTree,
    TimezoneMark,
  },
  mixins: [mixin],
  props: {
    // 主题样式
    themeClass: {
      default: '',
      type: String,
    },
    // 页面标题
    title: {
      default: '',
      type: String,
    },
    // 返回链接 或者 路由name  有backParams为name
    backUrl: {
      default: '',
      type: String,
    },
    // 返回链接路由参数
    backParams: {
      default: () => ({}),
      type: Object,
    },
    // 是否显示返回按钮
    showBack: {
      default: false,
      type: Boolean,
    },
    // 是否显示阴影
    showBoxshadow: {
      default: false,
      type: Boolean,
    },
    showSignIn: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showNavigation: false,
      childPopList: [],
      showItemPop: false,
      isAppJsInject: false,
      tipsList: [],
    }
  },
  computed: {
    // 是否显示阴影
    displayBoxshadow() {
      return this.showBoxshadow && !this.showNavigation
    },
    routerPathPrefix() {
      return this.$store.state.routerPathPrefix
    },
    loginPath() {
      const currentFullPath = this.$route.fullPath
      const successUrl = encodeURI(currentFullPath)
      return `/login?successUrl=${successUrl}&triggerSource=header`;
    },
    hasUnpaidList() {
      return (this.$store.state.ucenter.unpaidList.toBeContinueSku || this.$store.state.ucenter.unpaidList.inCartSku) && this.isNotReminded
    },
    isNotReminded() {
      const storage = new Storage();
      return !storage.getItem('figure_remind')
    },
  },
  watch: {
    isLogin(newVal) {
      const date = new Date().getTime()
      window.localStorage.setItem('closeAllTipsTime', date)
      window.localStorage.setItem('closeAllTipsStatus', false)
      if (newVal === true) {
        this.getEvalutaionTips()
      }
    },
  },
  mounted() {
    this.isAppJsInject = process.client ? !!window.JsInjectionActivity : false
    if (this.isLogin) {
      const date = new Date().getTime()
      const time = window.localStorage.getItem('closeAllTipsTime') || date
      if (date - time > 86400000) {
        window.localStorage.setItem('closeAllTipsTime', date)
        window.localStorage.setItem('closeAllTipsStatus', false)
      }
      const status = JSON.parse(window.localStorage.getItem('closeAllTipsStatus')) || false

      if (!status) {
        this.getEvalutaionTips()
      }
    }
  },
  methods: {
    handleShare() {
      this.$shareContent(window.location.href);
    },
    handleCloseItemNavPopup() {
      this.showItemPop = false
    },
    // 处理导航弹出层
    handleNavPopup() {
      this.showNavigation = true
    },
    // 处理关闭导航弹出层
    handleCloseNavPopup() {
      this.showNavigation = false
    },
    appHandleBack() {
      // 原生课程列表页返回原生
      if (isNativeCourses()) {
        this.$goBack(() => {}, true)
      } else {
        this.$goBack(() => {
          if (this.fromUrl) {
            this.$router.back()
          } else {
            this.$router.push('/courses')
          }
        }, !this.fromUrl)
      }
    },
    touchHandleBack() {
      if (this.routerPathPrefix) this.$router.push(this.routerPathPrefix)
      // 没有返回链接跳到首页
      if (!this.backUrl) this.$router.push('/')

      // 有返回链接且没有路由参数，直接跳转
      if (!this.backParams || Object.keys(this.backParams).length === 0) this.$router.push(this.backUrl)
      // 有返回链接且有路由参数，带参跳转
      else this.$router.push({ name: this.backUrl, params: this.backParams })
    },
    // 处理返回事件
    handleBack() {
      if (this.isAppJsInject) {
        this.appHandleBack()
      } else {
        this.touchHandleBack()
      }
    },
    // 处理标题点击事件
    handleTitleClick() {
      this.$emit('title-click')
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  top: 0;
  z-index: 101;

  &.sticky {
    position: sticky;
  }
}

.topbar {
  position: relative;
  z-index: 888;
  background: var(--txt-color-link);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  color: #fff;

  &.boxshadow {
    box-shadow: 0 1px 0 rgba(210, 210, 210, 0.5);
  }

  .flex-left {
    width: 50px;
  }

  .flex-right {
    display: flex;
    align-items: center;

    .timezone-mark-wrapper {
      margin-right: 20px;
    }

    .iconfont {
      color: #fff;
      font-size: 20px;
    }

    .menuImg {
      width: 28px;
      height: 28px;
    }
  }

  .header-button {
    .iconfont {
      color: #fff;
      font-size: 21px;
    }

    .icon-menu-outline {
      font-size: 21px;
    }

    .icon-close {
      font-size: 18px;
    }

    .icon-arrow-left-outline {
      margin-left: -5px;
      font-size: 18px;
    }
  }

  .logo {
    img {
      display: block;
      height: 26px;
    }
  }

  .title {
    font-size: 16px;

    .icon-arrow-bottom {
      font-size: 22px;
    }
  }

  .signin {
    font-size: 14px;
    color: #9e9ea7;
  }
}

.highBar {
  height: 60px;
}

.van-popup--right {
  width: 295px;
  height: 100%;
  background: #f7f7f8;
}

.user-status {
  display: flex;
  align-items: center;
  height: 128px;
  background: var(--txt-color-link);
  border-radius: 0 0 10px 10px;

  .circular-head {
    position: relative;
    width: 64px;
    height: 64px;
    border: 2px solid var(--txt-color-link3);
    border-radius: 50%;
    margin-right: 10px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;

      &.mark {
        position: absolute;
        right: 0;
        width: 12px;
        height: 12px;
      }
    }
  }

  .userName {
    margin: 0 auto;
    margin-bottom: 6px;
    font-size: 28px;

    .sign-btn {
      margin: 0 auto;
      width: 180px;
    }
  }

  .user-info {
    flex: 1;
    color: rgba(255, 255, 255, 1);
    overflow: hidden;

    .phone-number {
      font-size: 14px;
    }
  }

  .icon-arrow {
    margin-right: 15px;
    i { color: #fff; }
  }
}

.nav-header-item {
  padding: 10px 10px 0 10px;
  border-radius: 10px;
}

.navigation {
  height: 100%;

  .links {
    padding: 10px 25px 25px;

    .item {
      a {
        display: block;
        height: 60px;
        line-height: 60px;
        font-size: 14px;

        &.nuxt-link-exact-active {
          color: var(--txt-color-link);
          font-weight: bold;
        }
      }

      span {
        display: block;
      }
    }
  }
}

.navigation-wrapper {
  padding-bottom: 25px;
}

.skin-transparent {
  background-color: transparent;
}

.header-am,
.header-ae {
  .topbar {
    background: #fff;
    color: var(--txt-color-lv1);

    .flex-right {
      .icon-menu-outline {
        font-size: 20px;
        color: var(--txt-color-lv1);
      }
    }
  }

  .user-status {
    background: #2cc0b7;

    .sign-btn {
      color: #2cc0b7;
    }
  }
}
.tips-box{
    width: 343px;
    // height: 113px;
    position: absolute;
    right: 16px;
    top: 76px;
  .tips-an {
    width: 343px;
    background: rgba(34,34,34,0.8);
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.3);
    backdrop-filter: blur(10px);
    padding: 16px;
    animation: shang .4s ease-in forwards;
    animation-delay: .5s;
    transform: translateY(50px);
    opacity: 0;
    // height: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    .card {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .l {
        width: 36px;
        margin-right: 8px;
        img{
          width: 100%;
        }
      }
      .r {
        flex: 1;
        .p1 {
          width: 230px;
          font-size: 14px;
          font-family: Montserrat-SemiBold, Montserrat;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 18px;
          span {
            text-decoration: underline;
            cursor: pointer;
            color: #FFA827;
          }
        }
        .p2 {
          font-size: 12px;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 15px;
          margin-top: 12px;
          span {
            text-decoration: underline;
            cursor: pointer;
            color: #FFA827;
          }
        }
      }
    }
    .close {
      position: absolute;
      cursor: pointer;
      width: 30px;
      height: 30px;
      right: 10px;
      top: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@keyframes shang {
  0%{
    transform: translateY(50px);
    opacity: 0;
    height: 0;
  }
  1%{
    transform: translateY(50px);
    opacity: 0;
    // height: 113px;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
    // height: 113px;
  }
}
</style>
